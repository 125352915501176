import React, { useState } from "react";
import DocumentationPageLayout from "@src/components/DocumentationPageLayout";
import { Box, Flex } from "@src/components/Boxes";
import { graphql, useStaticQuery } from "gatsby";
import { DataContentStyled, SelectorBadge, SelectorBadgeImg } from "../components/transparency.styled";

const DataContent = ({ setTab, tab }) => {
  const data = useStaticQuery(graphql`
    query {
      everyoneIcon: file(name: { eq: "everyone_badge" }) {
        publicURL
      }
      teacherIcon: file(name: { eq: "teacher_badge" }) {
        publicURL
      }
      parentIcon: file(name: { eq: "parent_badge" }) {
        publicURL
      }
      studentIcon: file(name: { eq: "student_badge" }) {
        publicURL
      }
      leaderIcon: file(name: { eq: "school_leader_badge" }) {
        publicURL
      }
      loggedOutIcon: file(name: { eq: "logged-out_badge" }) {
        publicURL
      }
      outsideUserIcon: file(name: { eq: "outside_user" }) {
        publicURL
      }
    }
  `);
  const { everyoneIcon, teacherIcon, parentIcon, studentIcon, leaderIcon, loggedOutIcon, outsideUserIcon } = data;

  function badgeClick(badge) {
    setTab(badge);
  }

  return (
    <DataContentStyled>
      <div className="page-section">
        <Flex alignItems="center">
          <Box width={[0, 0, 2 / 12]} display="inline-block">
            <div className="hidden-sm hidden-xs">
              <img
                src="https://static.classdojo.com/img/page_terms/hero-terms.png"
                className="header-img img-responsive"
                alt="monster logo"
              />
            </div>
          </Box>
          <Box width={["100%", "100%", 10 / 12]} display="inline-block">
            <p className="lead">
              ClassDojo is committed to being transparent with how we handle your information, in full compliance with
              local, national, and international laws like GDPR, COPPA, FERPA, and others.
            </p>
          </Box>
        </Flex>
        <h4 className="text-center">
          Below you can see what information we collect, why and how we collect it, where it's stored, and how it's
          different for each type of user on our platform.
        </h4>
        <Flex width="100%" ml="-15px" flexDirection={["column", "row"]} mx="auto">
          <SelectorBadge onClick={() => badgeClick("everyone")} selected={tab === "everyone"}>
            <SelectorBadgeImg src={everyoneIcon.publicURL} alt="" />
            Everyone
          </SelectorBadge>
          <SelectorBadge onClick={() => badgeClick("teacher")} selected={tab === "teacher"}>
            <SelectorBadgeImg src={teacherIcon.publicURL} alt="" />
            Teacher
          </SelectorBadge>
          <SelectorBadge onClick={() => badgeClick("parent")} selected={tab === "parent"}>
            <SelectorBadgeImg src={parentIcon.publicURL} alt="" />
            Parent
          </SelectorBadge>
          <SelectorBadge onClick={() => badgeClick("student")} selected={tab === "student"}>
            <SelectorBadgeImg src={studentIcon.publicURL} alt="" />
            Student
          </SelectorBadge>
          <SelectorBadge onClick={() => badgeClick("school-leader")} selected={tab === "school-leader"}>
            <SelectorBadgeImg src={leaderIcon.publicURL} alt="" />
            School Leader
          </SelectorBadge>
          <SelectorBadge onClick={() => badgeClick("logged-out")} selected={tab === "logged-out"}>
            <SelectorBadgeImg src={loggedOutIcon.publicURL} alt="" />
            Logged out Users
          </SelectorBadge>
          <SelectorBadge onClick={() => badgeClick("outside-user")} selected={tab === "outside-user"}>
            <SelectorBadgeImg src={outsideUserIcon.publicURL} alt="" />
            Outside School User
          </SelectorBadge>
        </Flex>

        <h2>Types of information shared</h2>
      </div>
      <div className="page-section legal-content data-transparency">
        {"teacher parent school-leader outside-user student everyone".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student everyone">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#FirstAndLastName">
                  First and Last Name
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="FirstAndLastName">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, and Student (via teacher)</p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  Directly by the user on our website, or mobile app, or if its the student by the teacher; via the
                  School Directory by teachers and school leaders; also, when users fill out surveys or contact us via
                  Customer Service/email
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  Establishing one's identity within a school community, or for support needs/responding to surveys; to
                  invite more teachers via the School Directory
                </p>
                <h4>How this information is used</h4>
                <p>
                  To send an SMS message to invite a (potentially) non-logged in user to ClassDojo; if for support or a
                  survey, to let a ClassDojo team member contact the individual
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  Data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); Zendesk in the U.S.; SurveyMonkey in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>Sendgrid in the U.S., to help us send friendlier emails</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher outside-user logged-out".indexOf(tab) !== -1 && (
          <div data-selector className="teacher logged-out">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#PhoneNumberMobileNumber">
                  Phone number/mobile number
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="PhoneNumberMobileNumber">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher (to invite other teachers or parents) Non-logged-in users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  User enters phone number on our website or within the app to invite another teacher; non-logged-in
                  users can optionally submit via a support request and/or survey
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  To send an SMS to invite a user to join ClassDojo; if for support or a survey, to let a ClassDojo team
                  member contact the individual
                </p>
                <h4>How this information is used</h4>
                <p>
                  To send an SMS message to invite a (potentially) non-logged in user to ClassDojo; if for support or a
                  survey, to let a ClassDojo team member contact the individual
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  Data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); Zendesk in the U.S.; SurveyMonkey in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>n/a - This feature only works in the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher outside-user parent school-leader".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#EmailAddress">
                  Email address
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="EmailAddress">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, and School leader</p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  directly by the user on our website, or mobile app, or if its the student by the teacher; also, when
                  users fill out surveys or contact us via Customer Service/email
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>Establishing one's identity within a school community, or for support needs/responding to surveys</p>
                <h4>How this information is used</h4>
                <p>
                  To identify individuals within the school; for example, when adding a post to School Story, we know
                  which teacher posted it.
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); Zendesk in the U.S.; SurveyMonkey in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>Sendgrid in the U.S., so that we can email you when needed</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user student".indexOf(tab) !== -1 && (
          <div data-selector className="student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#AppOrProductUsername">
                  App or product username
                </a>
              </h3>
              <div>
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="AppOrProductUsername">
              <div>
                <h4>Account types that share this information</h4>
                <p>Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>from the student when registering on classdojo.com or in the mobile app</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>to allow a student to log in and have an account</p>
                <h4>How this information is used</h4>
                <p>
                  to allow a student to log in and have an account; to let teachers remind students of their username so
                  they can log in; when contacting customer service for help
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); Zendesk in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#Password">
                  Password
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="Password">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher,Parent,School leader, and Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>from the user when registering on classdojo.com or in the mobile app</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>to allow users to log in and have an account</p>
                <h4>How this information is used</h4>
                <p>
                  to allow users to log in and have an account; student passwords can be reset by teachers to an
                  automatically generated password when needed
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#MobileDeviceID">
                  Mobile device ID
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="MobileDeviceID">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher,Parent,School leader, and Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically from the device when using the ClassDojo mobile app</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  To help identify the types of devices being used by different users, to improve the product, and to
                  diagnose issues/bugs
                </p>
                <h4>How this information is used</h4>
                <p>
                  To help identify the types of devices being used by different users, to improve the product, and to
                  diagnose issues/bugs
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S.; back-ups are in the same locations; also tracked by
                  Crashlytics (U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user student".indexOf(tab) !== -1 && (
          <div data-selector className="student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#Age">
                  Age
                </a>
              </h3>
              <div>
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="Age">
              <div>
                <h4>Account types that share this information</h4>
                <p>Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  We ask students for their age when they create an account without teacher or parent help for their age
                  on the classdojo.com website and the mobile apps.
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  To determine if we need parental consent for students to share personal information with ClassDojo.
                </p>
                <h4>How this information is used</h4>
                <p>
                  To determine if we need parental consent for students to share personal information with ClassDojo.
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher parent school-leader".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#gender">
                  Gender
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="gender">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher,Parent and School leader</p>
                <h4>How ClassDojo collects this data</h4>
                <p>During account creation, we ask for a title (e.g., Mr.,/Mrs.) on the the website and mobile apps</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  To help with identity within a school community (e.g., addressing a teacher/parent in the correct
                  manner, like Mr. John Doe)
                </p>
                <h4>How this information is used</h4>
                <p>
                  To identify individuals properly within the school; for example, when adding a post to School Story,
                  we include a persons' title; also to properly refer to someone when we email them updates
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB in the U.S.; back-ups are in the same locations
                  (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>Sendgrid in the U.S., to help us send friendlier emails</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#LanguageInformation">
                  Language information (native/preferred/primary language spoken by a student)
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="LanguageInformation">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher,Parent, School leader and Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  Either from the device's language settings and/or on our www.classdojo.com website based on browser
                  settings and/or user choice from a language dropdown
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To provide the Service in the user's preferred language</p>
                <h4>How this information is used</h4>
                <p>To provide the Service in the user's preferred language</p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB in the U.S.; back-ups are in the same locations
                  (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>Sendgrid in the U.S., to help us send emails in your preferred language</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher school-leader".indexOf(tab) !== -1 && (
          <div data-selector className="teacher school-leader">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#SchoolName">
                  School name
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="SchoolName">
              <div>
                <h4>Teacher, Parent, School leader, and Student</h4>
                <p>
                  Teacher and school leader; associated with parent and student accounts, but not collected from them
                </p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  directly by teachers and school leaders on our website, or mobile app during signup; also, when users
                  fill out surveys or contact us via Customer Service/email
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  Being able to create connections within a school community amongst teachers, parents, students, and
                  school leaders
                </p>
                <h4>How this information is used</h4>
                <p>
                  Being able to create connections within a school community amongst teachers, parents, students, and
                  school leaders; also used when responding to customer service inquiries, as well as in emails we send
                  to users about activity within their school
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); SurveyMonkey in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>Sendgrid in the U.S., to help us send emails relevant to you and your school</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#SchoolAddress">
                  School Address
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="SchoolAddress">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, and Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>directly by teachers and school leaders on our website, or mobile app during signup</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  Being able to create connections within a school community amongst teachers, parents, students, and
                  school leaders; address helps ensure users are in the correct schools since many schools share the
                  same name
                </p>
                <h4>How this information is used</h4>
                <p>
                  Being able to create connections within a school community amongst teachers, parents, students, and
                  school leaders
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher parent school-leader student logged-out".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student logged-out">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#LocalSchoolDistrictIDNumber">
                  Local (school district) ID number
                </a>
              </h3>
            </div>
            <div className="collapse legal-body" id="LocalSchoolDistrictIDNumber">
              <div>
                <h4>Account types that share this information</h4>
                <p>Not collected from any user</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Acquired via one of our service providers, MDR Education</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  To improve the quality of the names and addresses of U.S. schools, and a variety of school-level
                  features, like school start and end dates to improve teacher usability and School Directory management
                </p>
                <h4>How this information is used</h4>
                <p>
                  To improve the quality of our school database, and to help teachers better improve their own School
                  Directory
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>n/a</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>n/a</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher parent school-leader".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#GeolocationData">
                  Geolocation data (Precise)
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="GeolocationData">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, and School leader</p>
                <h4>How ClassDojo collects this data</h4>
                <p>
                  During account creation from the web or mobile apps, teachers/school leaders provide precise location
                  information in order to search our school list to choose which school they are in; this is using GPS
                  and cellular network information to provide precise location; parents, when not provided a unique
                  code, can also search for their school using precise location information
                </p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To search for their school, by highlighting schools closest to them based on precise location</p>
                <h4>How this information is used</h4>
                <p>
                  To let teachers search for their school, by highlighting schools closest to them based on precise
                  location
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>Algolia and AWS (Elastic search) in the U.S.; back-ups are in the same locations</p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a
                  className="toggle-link"
                  data-toggle="collapse"
                  href="#PhotographsVideosDocumentsDrawingsOrAudioFiles"
                >
                  Photographs, videos, documents, drawings, or audio files
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="PhotographsVideosDocumentsDrawingsOrAudioFiles">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader and Student</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Product features (messaging, Class Story, School Story, Portfolios)</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  Letting users communicate with each other; for students, letting them share their classwork on a
                  digital portfolio for their teacher(s) and parent(s) to see
                </p>
                <h4>How this information is used</h4>
                <p>
                  Letting users communicate with each other; for students, letting them share their classwork on a
                  digital portfolio for their teacher(s) and parent(s) to see
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher".indexOf(tab) !== -1 && (
          <div data-selector className="teacher">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#StudentClassAttendanceData">
                  Student class attendance data
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="StudentClassAttendanceData">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Product feature on web and mobile apps</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>
                  To improve the service for teachers (e.g., only letting teachers give feedback points to students
                  attending class on a given day); also, teachers have access to historical student class attendance
                  data
                </p>
                <h4>How this information is used</h4>
                <p>
                  To improve the service for teachers (e.g., only letting teachers give feedback points to students
                  attending class on a given day); also, teachers have access to historical student class attendance
                  data
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher".indexOf(tab) !== -1 && (
          <div data-selector className="teacher">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#FeedbackPoints">
                  Feedback points
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="FeedbackPoints">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Product feature on web and mobile apps</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To let teachers give feedback points to students in their classes</p>
                <h4>How this information is used</h4>
                <p>
                  Teachers part of a class, as well as verified teachers and school leaders in a school, the student
                  themself, and the student's parents can view the feedback points
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.)
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student logged-out".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student logged-out">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#IPAddress">
                  IP Address (from which we can estimate a coarse geolocation)
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="IPAddress">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>For all server requests from web and mobile clients</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To provide the classdojo service</p>
                <h4>How this information is used</h4>
                <p>
                  Transmit data back to the requesting browser or mobile client. Provide information necessary for the
                  operations of our servers, such as security and quality of service; coursely geolocate requests to
                  improve the product for users
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student logged-out".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student logged-out">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#BrowserDetails">
                  Browser details
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="BrowserDetails">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>For all server requests from web clients and mobile clients using a web agent</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To provide a working classdojo experience tailored to the user's browser</p>
                <h4>How this information is used</h4>
                <p>
                  Responses will differ based on the client used so that client features will only be enabled when
                  supported. Trends in browser type will inform which browsers ClassDojo will support in new software
                  releases
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Legitimate interest and performance of contract</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#AccessTime">
                  Access time
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="AccessTime">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#TimeSpentOnSite">
                  Time spent on site
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="TimeSpentOnSite">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent logged-out school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#PageViews">
                  Page Views
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="PageViews">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"teacher logged-out parent school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#ReferringURLs">
                  Referring URLs
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="ReferringURLs">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent logged-out school-leader student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#Clicks">
                  Clicks
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="Clicks">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader student logged-out".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#ClickPaths">
                  Click paths
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="ClickPaths">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
        {"outside-user teacher parent school-leader logged-out student".indexOf(tab) !== -1 && (
          <div data-selector className="teacher parent school-leader student">
            <div className="data-title-bar">
              <h3 className="legal-toggle">
                <a className="toggle-link" data-toggle="collapse" href="#ActiveEngagementTime">
                  Active/engagement time
                </a>
              </h3>
              <div>
                <div className="small-badge-container teacher-small-badge" />
                <div className="small-badge-container parent-small-badge" />
                <div className="small-badge-container student-small-badge" />
                <div className="small-badge-container school_leader-small-badge" />
                <div className="small-badge-container logged_out-small-badge" />
                <div className="small-badge-container outside_user-small-badge" />
              </div>
            </div>
            <div className="collapse legal-body" id="ActiveEngagementTime">
              <div>
                <h4>Account types that share this information</h4>
                <p>Teacher, Parent, School leader, Student and logged out users</p>
                <h4>How ClassDojo collects this data</h4>
                <p>Automatically based on website or app usage</p>
                <h4>The purpose for ClassDojo collecting this information</h4>
                <p>To improve our product knowledge and for improving marketing emails and notifications delivery</p>
                <h4>How this information is used</h4>
                <p>
                  To improve our product knowledge about how people use our products, and to better deliver marketing
                  emails to users based on their actual usage
                </p>
                <h4>Where is this data stored (and which third-party service providers hold it, if any)</h4>
                <p>
                  data stored on AWS servers in the U.S. and MongoDB Atlas in the U.S.; back-ups are in the same
                  locations (AWS/MongoDB Atlas in the U.S.); also DataDog, and Google Analytics in the U.S.
                </p>
                <h4>
                  Is this data shared with any other third-party service providers, and if so, who and for what reason?
                </h4>
                <p>n/a</p>
                <h4>Is this information transferred outside of the European Economic Area (EEA)?</h4>
                <p>Yes - to the U.S.</p>
                <h4>What is the legal basis for processing this information under the GDPR?</h4>
                <p>Decided by Controller; ClassDojo is acting as a processor</p>
              </div>
            </div>
            <hr />
          </div>
        )}
      </div>
    </DataContentStyled>
  );
};

function getMenu(tab) {
  return `
        <ul class="list-unstyled hidden-xs sub-menu quick-reference-links">

          ${
            "outside-user teacher parent school-leader student everyone".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#FirstAndLastName">First and Last Name</a></li>'
              : ""
          }

          ${
            "teacher logged-out".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#PhoneNumberMobileNumber">Phone number/mobile number</a></li>'
              : ""
          }

          ${
            "teacher parent school-leader".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#EmailAddress">Email address</a></li>'
              : ""
          }

          ${
            "outside-user student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#AppOrProductUsername">App or product username</a></li>'
              : ""
          }

          ${
            "teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#Password">Password</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#MobileDeviceID">Mobile device ID</a></li>'
              : ""
          }

          ${
            "outside-user student".indexOf(tab) !== -1 ? '<li><a class="depress-on-click" href="#Age">Age</a></li>' : ""
          }

          ${
            "teacher parent school-leader".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#Gender">Gender</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#LanguageInformation">Language information (native/preferred/primary language spoken by a student)</a></li>'
              : ""
          }

          ${
            "teacher school-leader".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#SchoolName">School name</a></li>'
              : ""
          }

          ${
            "teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#SchoolAddress">SchoolAddress</a></li>'
              : ""
          }

          ${
            "teacher parent school-leader student logged-out".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#LocalSchoolDistrictIDNumber">Local (school district) ID number</a></li>'
              : ""
          }

          ${
            "teacher parent school-leader".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#GeolocationData">Geolocation data (Precise)</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#PhotographsVideosDocumentsDrawingsOrAudioFiles">Photographs, videos, documents, drawings, or audio files</a></li>'
              : ""
          }

          ${
            "teacher".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#StudentClassAttendanceData">Student class attendance data</a></li>'
              : ""
          }

          ${
            "teacher".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#FeedbackPoints">Feedback points</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student logged-out".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#IPAddress">IP Address (from which we can estimate a coarse geolocation)</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student logged-out".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#BrowserDetails">Browser details</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#AccessTime">AccessTime</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#TimeSpentOnSite">Time spent on site</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#PageViews">Page Views</a></li>'
              : ""
          }

          ${
            "teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#ReferringURLs">Referring URLs</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#Clicks">Clicks</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#ClickPaths">Click paths</a></li>'
              : ""
          }

          ${
            "outside-user teacher parent school-leader student".indexOf(tab) !== -1
              ? '<li><a class="depress-on-click" href="#ActiveEngagementTime">Active/engagement time</a></li>'
              : ""
          }
        </ul>`;
}

function Transparency() {
  const [tab, setTab] = useState("everyone");

  return (
    <DocumentationPageLayout
      content={{
        menu: getMenu(tab),
        componentContent: <DataContent setTab={setTab} tab={tab} />,
      }}
      title="Information Transparency"
      seoTitle="page_titles.data"
      seoDescription="page_descriptions.data"
      type="data"
    />
  );
}

export default Transparency;
